* {
  font-family: "Roboto", sans-serif;
}

.children-heading {
  font-size: 20px;
  font-weight: 500;
}

.primary-color {
  color: #333;
}

.secondary-color {
  color: #0050ef;
}

.primary-button {
  background-color: #0050ef;
  border: none !important;
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

.primary-button:hover {
  background-color: #3e7fff !important;
}

input[type="email"]:focus {
  border-color: var(--secondary-color) !important;
}

input[type="password"]:focus {
  border-color: var(--secondary-color) !important;
}
input {
  box-shadow: none !important;
  outline: none !important;
}

input:focus,
input:active {
  /* outline: none !important; */
  /* box-shadow: none !important; */
  border-color: var( --secondary-color) !important;
  /* background-color: #0050ef !important ; */
  border: 2px solid;
}

input[type="text"]:focus {
  outline: none !important;
  /* outline-offset: 1px !important; */
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* For Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  box-shadow: none !important;
  outline: none !important;
}

textarea:focus,
textarea:active {
  outline: none !important;
  box-shadow: none !important;
  border-color: var( --secondary-color) !important;
  /* background-color: #0050ef !important ; */
  border: 2px solid;
}

.btn-primary:focus,
.btn-primary:active {
  outline: none !important;
  box-shadow: none !important;
  /* background-color: #0050ef !important ; */
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #797979 !important;
  font-size: 12px !important;
  font-family: "SF Pro Rounded Regular";
}


.fw-500 {
  font-weight: 500;
}

.help-block {
  color: var(--icon-color);
  font-size: 11px;
}

.form-select:focus {
  box-shadow: none;
  border-color: var(--input-border-color);
}


:root {
  --secondary-bg-color: #eff4ff;
  --outer-stepper-bg-color: #c8daff;
  --default-outer-stepper-bg-color: rgba(221, 221, 211, 0.3);
  --default-inner-stepper-bg-color: #d7d4d4;
  --secondary-color: #0050ef;
  --primary-color: #333;
  --icon-color: #686f7a;
  --input-border-color: #efefef;
  --card-border-color: #dee2e6;
  --card-bg-color: #fff
}